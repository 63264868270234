import { GA4Property } from 'services/local/types';
import { HTTPFunctions, SignUpData, SignIn, GmailSignInData } from './types';

type ResponseData = {
  data: any;
}

const getResponseData = (response: ResponseData) => response.data;

const auth = ({ wrapper, instance, get, put, postGmail, del, post }: HTTPFunctions) => ({
  signUp: (data: SignUpData) => wrapper(instance.post("/users/open", data)),
  logout: () => localStorage.removeItem("user"),
  signIn: (data: SignIn) => {
    const params = new URLSearchParams();
    params.append("username", data.email);
    params.append("password", data.password);
    if (data.reportId) {
      params.append("client_id", data.reportId);
    }
    return wrapper(instance.post("/login/access-token", params));
  },
  gmailSignIn: (data: GmailSignInData) => postGmail("/swap_token", data),
  passwordRecovery: (username: string) =>
    wrapper(instance.post(`/password-recovery/${username}`, {}).then(getResponseData)),
  resetPassword: (new_password: string, token: string) =>
    wrapper(instance.post("/reset-password/", { new_password, token }).then(getResponseData)),
  newPassword: (old_password: string, new_password: string) =>
    put( '/users/password', {old_password, new_password}).then(getResponseData),
  validateEmail: (token: string) => wrapper(instance.get(`/users/email_confirmation/${token}`)),
  getAccountSettings: () => get('/users/me'),
  setAccountSettings: (data: any) => put('/users/me', data),
  deleteAccountSettings: () => del('/users/me'),
  sendGA4Code: (code: string) => get(`google-analytics/callback?code=${code}`),
  sendGA4Account: (accountId: string, property: GA4Property) => post('google-analytics/grant/', {
    property_name: property.name, property_display_name: property.display_name, account_id: accountId
  }),
  revokeGA4: () => del('google-analytics/revoke/'),
});

export default auth;
