import cookies from './local/cookies';
import auth from './local/auth';
import analysis from './local/analysis';
import firstTime from './local/firstTime';
import tutorials from './local/tutorials';
import wordCloud from './local/wordCloud';
import cardStates from './local/cardStates';
import preferences from './local/preferences';
import productSaved from './local/productSaved';
import summaryOrder from './local/summaryOrder';
import leftMenuState from './local/leftMenuState';
import mobileWarning from './local/mobileWarning';
import choiceAsDefault from './local/choiceAsDefault';
import compareDocument from './local/compareDocument';
import uxFeaturesOrder from './local/uxFeaturesOrder';
import touchPointsZoom from './local/touchPointsZoom';
import selectedDocuments from './local/selectedDocuments';
import wordCloudSearched from './local/wordCloudSearched';
import notEditableWarning from './local/notEditableWarning';
import webCheckerDocuments from './local/webCheckerDocuments';
import customerMetricsOrder from './local/customerMetricsOrder';
import localStorageFunctions from './local/localStorageFunctions';
import webCheckerEcommerceDetails from 'services/local/webCheckerEcommerceDetails';
import type { Local } from './local/types';

const getLocal = (fn: Storage): Local => ({
  auth: auth(localStorageFunctions(fn)),
  cookies: cookies(localStorageFunctions(fn)),
  analysis: analysis(localStorageFunctions(fn)),
  firstTime: firstTime(localStorageFunctions(fn)),
  tutorials: tutorials(localStorageFunctions(fn)),
  wordCloud: wordCloud(localStorageFunctions(fn)),
  cardStates: cardStates(localStorageFunctions(fn)),
  preferences: preferences(localStorageFunctions(fn)),
  productSaved: productSaved(localStorageFunctions(fn)),
  summaryOrder: summaryOrder(localStorageFunctions(fn)),
  leftMenuState: leftMenuState(localStorageFunctions(fn)),
  mobileWarning: mobileWarning(localStorageFunctions(fn)),
  choiceAsDefault: choiceAsDefault(localStorageFunctions(fn)),
  compareDocument: compareDocument(localStorageFunctions(fn)),
  uxFeaturesOrder: uxFeaturesOrder(localStorageFunctions(fn)),
  touchPointsZoom: touchPointsZoom(localStorageFunctions(fn)),
  selectedDocuments: selectedDocuments(localStorageFunctions(fn)),
  wordCloudSearched: wordCloudSearched(localStorageFunctions(fn)),
  notEditableWarning: notEditableWarning(localStorageFunctions(fn)),
  webCheckerDocuments: webCheckerDocuments(localStorageFunctions(fn)),
  customerMetricsOrder: customerMetricsOrder(localStorageFunctions(fn)),
  webCheckerEcommerceDetails: webCheckerEcommerceDetails(localStorageFunctions(fn))
});

export default getLocal;
